import React from "react";
import { TenantSpaces } from "./pages/entities/TenantSpaces";
import { SpaceDetails } from "./pages/entities/SpaceDetails";
import { ProcessDetails } from "./pages/entities/ProcessDetails";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppLayout } from "./AppLayout";
import { ConfigProvider } from "antd";
import { SpaceContext } from "./contexts/SpaceContext";
import { ProcessContext } from "./contexts/ProcessContext";
import { GmailIInbox } from "./tests/gmail";
import { RedirectTo } from "./addon/retirectTo";

function App() {
  const [space, setSpace] = React.useState({ cSpaceId: "", cSpaceName: "" });
  const [process, setProcess] = React.useState({
    cProcessId: "",
    cProcessName: "",
  });

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              headerHeight: 96,
            },
            Tree: {
              titleHeight: 36,
            },
          },
          token: {
            fontFamily: "Lato",
          },
        }}
      >
        <SpaceContext.Provider value={{ space, setSpace }}>
          <ProcessContext.Provider value={{ process, setProcess }}>
            {/* Add the Routes component */}
            <Routes>
              <Route
                path="/"
                element={
                  <AppLayout pageTitle="Spaces">
                    <TenantSpaces />
                  </AppLayout>
                }
              />
              <Route
                path="/tenant/:tenantId"
                element={
                  <AppLayout pageTitle="Shared Tenant">
                    <TenantSpaces />
                  </AppLayout>
                }
              />
              <Route
                path="/space/:spaceId"
                element={
                  <AppLayout pageTitle={space.cSpaceName}>
                    <SpaceDetails />
                  </AppLayout>
                }
              />

              <Route
                path="/process/:processId"
                element={
                  <AppLayout pageTitle={process.cProcessName}>
                    <ProcessDetails />
                  </AppLayout>
                }
              />
              <Route
                path="/addon_redirect/:redirect_to"
                element={<RedirectTo />}
              />
              <Route path="/test/gmail" element={<GmailIInbox />} />
            </Routes>
          </ProcessContext.Provider>
        </SpaceContext.Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
