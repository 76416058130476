import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import {
  ProcedureCycle,
  ProcessCycle,
  StepCycle,
  WorkPriority,
  WorkStatus,
} from "../../gql/graphql"; // Assuming ProcessCycle is the correct type
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  List,
  Modal,
  Row,
  Table,
  TableProps,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import React, { useEffect, useState } from "react";
import CycleTree from "../misc/CycleTree";
import ProcessCycleForm from "../forms/ProcessCycleForm";
import ReactDOM from "react-dom";
import { CYCLE_DETAILS } from "../../shared/fragments";
import { SelectProcessCycleStatus } from "../../components/SelectStatus";
import {
  CREATE_PROCESS_CYCLE,
  UPDATE_PROCESS_CYCLE,
} from "../../shared/mutations";
import { format, formatDistanceToNow, isPast, isToday } from "date-fns";
import _ from "lodash";
import { isMobile } from "react-device-detect";
import Column from "antd/es/table/Column";
import Item from "antd/es/descriptions/Item";

// Define the GraphQL query
const GET_PROCESS_CYCLES_INFO = gql`
  ${CYCLE_DETAILS}
  query getProcessCyclesByProcess($id: ID!) {
    processCyclesByProcess(id: $id) {
      ...CycleDetails
    }
  }
`;
// Define the GraphQL query
const GET_TENANT_CYCLES_INFO = gql`
  ${CYCLE_DETAILS}
  query getProcessCyclesByTenant($id: ID!) {
    processCyclesByTenant(id: $id) {
      ...CycleDetails
    }
  }
`;

// Define the GraphQL query
const GET_SPACE_CYCLES_INFO = gql`
  ${CYCLE_DETAILS}
  query getProcessCyclesBySpace($id: ID!) {
    processCyclesBySpace(id: $id) {
      ...CycleDetails
    }
  }
`;

export function useCreateProcessCycleMutation() {
  return useMutation(CREATE_PROCESS_CYCLE);
}

export const UPDATE_PROCESS_CYCLES_STATUS = gql`
  mutation updateProcessCyclesStatus($ids: [ID!]!, $status: WorkStatus!) {
    updateProcessCyclesStatus(ids: $ids, status: $status) {
      id
      status
    }
  }
`;
export function useUpdateProcessCyclesStatusMutation() {
  return useMutation(UPDATE_PROCESS_CYCLES_STATUS);
}

// Add cyclesBy type prop with values "PROCESS" or "SPACE"
interface CyclesTableProps {
  cyclesBy: "PROCESS" | "SPACE" | "TENANT";
  id: string | null;
}

// Define a ProcessInfo type to match the GraphQL query
interface ProcessInfo {
  key: string;
  cycle: string;
  status: string;
  priority: string;
  deadline: string;
  description: string;
  process: string;
  processId: string;
  currentProcedure: string;
}

const CyclesTable: React.FC<CyclesTableProps> = ({ id, cyclesBy }) => {
  const ModalStates = {
    OFF: "OFF",
    ADD: "ADD",
    EDIT: "EDIT",
  };

  const [hoveredRow, setHoveredRow] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [selectedCycleId, setSelectedCycleId] = useState("");
  const [selectedCycleStatus, setSelectedCycleStatus] = useState("");
  const [cycleTreeData, setCycleTreeData] = useState<string | null>(null);
  const [cycleTreeChecked, setCycleTreeChecked] = useState<string[]>([]);
  const [cycleTreeExpanded, setCycleTreeExpanded] = useState<string[]>([]);
  const [modalState, setModalState] = useState(ModalStates.OFF);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableData, setTableData] = useState<ProcessInfo[]>([]);
  const [cyclesData, setCyclesData] = useState<ProcessCycle[]>([]);
  function useUpdateProcessCycleMutation() {
    return useMutation(UPDATE_PROCESS_CYCLE);
  }
  const [updateProcessCycle, { data: updateProcessCycleData }] =
    useUpdateProcessCycleMutation();
  const [createProcessCycle, { data: createProcessCycleData }] =
    useCreateProcessCycleMutation();
  const [updateProcessCyclesStatus] = useUpdateProcessCyclesStatusMutation();

  const { loading, error, data, refetch } = useQuery(
    cyclesBy === "PROCESS"
      ? GET_PROCESS_CYCLES_INFO
      : cyclesBy === "TENANT"
      ? GET_TENANT_CYCLES_INFO
      : GET_SPACE_CYCLES_INFO,
    {
      variables: { id },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (!loading) {
      const result =
        cyclesBy === "PROCESS"
          ? data?.processCyclesByProcess
          : cyclesBy === "TENANT"
          ? data?.processCyclesByTenant
          : data?.processCyclesBySpace;
      setTableData(
        result?.map((cycle: ProcessCycle) => {
          // Find the first procedure cycle where the status is not "Skipped" or "Done"
          const currentProcedureCycle = cycle.procedureCycles?.find(
            (procedureCycle) =>
              procedureCycle?.status !== "Skipped" &&
              procedureCycle?.status !== "Done"
          );

          return {
            key: cycle.id,
            cycle: cycle.title,
            status: cycle.status,
            priority: cycle.priority,
            description: cycle.description || "",
            deadline: cycle.deadline || "",
            process: cycle.process?.title || "",
            spaceTitle: cycle.process?.spaceTitle || "",
            spaceId: cycle.process?.spaceId || "",
            processId: cycle.process?.id || "",
            // Add the currentProcedure property
            currentProcedure: currentProcedureCycle?.procedure?.title || "",
          };
        })
      );
      setCyclesData(result || []);
      if (selectedCycleId && result) {
        result.map((cycle: ProcessCycle) => {
          if (cycle.id === selectedCycleId) {
            setSelectedCycleStatus(cycle.status || "");
          }
        });
      }
      console.log("data from processCycles", data);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;

  if (error) {
    if (
      error.networkError &&
      "statusCode" in error.networkError &&
      error.networkError.statusCode === 401
    ) {
      window.location.reload();
      return null;
    }
    return <p>Error: {error.message}</p>;
  }

  const handleCancel = () => {
    setModalState(ModalStates.OFF);
  };

  const handleEditCycle = (entity: any) => {
    setSelectedRow(entity);
    setModalState(ModalStates.EDIT);
  };

  const handleSaveProcessCycle = async (processCycle: any) => {
    if (modalState === ModalStates.ADD) {
      try {
        const result = await createProcessCycle({
          variables: {
            title: processCycle.title,
            description: processCycle.description,
            processId: id,
            status: WorkStatus.ToDo,
            priority: processCycle.priority,
          },
        });

        const currentProcedureCycle =
          result.data.createProcessCycle.procedureCycles?.find(
            (procedureCycle: any) =>
              procedureCycle?.status !== "Skipped" &&
              procedureCycle?.status !== "Done"
          );
        const newTableData: ProcessInfo = {
          key: result.data.createProcessCycle.id,
          cycle: result.data.createProcessCycle.title,
          status: result.data.createProcessCycle.status,
          process: result.data.createProcessCycle.process.title,
          priority: result.data.createProcessCycle.priority,
          deadline: result.data.createProcessCycle.deadline,
          description: result.data.createProcessCycle.description,
          processId: result.data.createProcessCycle.process.id,
          currentProcedure: currentProcedureCycle?.procedure?.title || "",
        };

        setTableData([...tableData, newTableData]);
        setCyclesData([...cyclesData, result.data.createProcessCycle]);

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (modalState === ModalStates.EDIT) {
      try {
        const result = await updateProcessCycle({
          variables: {
            id: processCycle.id, // Provide the processCycle ID to identify which processCycle to update
            title: processCycle.title,
            description: processCycle.description,
            priority: processCycle.priority,
            deadline: processCycle.deadline,
            status: processCycle.status,
          },
        });
        const currentProcedureCycle =
          result.data.updateProcessCycle.procedureCycles?.find(
            (procedureCycle: any) =>
              procedureCycle?.status !== "Skipped" &&
              procedureCycle?.status !== "Done"
          );
        setTableData((tabbleData) =>
          tableData.map((item) =>
            item.key === processCycle.id
              ? {
                  key: result.data.updateProcessCycle.id,
                  id: result.data.updateProcessCycle.id,
                  cycle: result.data.updateProcessCycle.title,
                  process: result.data.updateProcessCycle.process.title,
                  priority: result.data.updateProcessCycle.priority,
                  deadline: result.data.updateProcessCycle.deadline,
                  status: result.data.updateProcessCycle.status,
                  processId: result.data.updateProcessCycle.process.id,
                  description: result.data.updateProcessCycle.description,
                  currentProcedure:
                    currentProcedureCycle?.procedure?.title || "",
                }
              : item
          )
        );
        setCyclesData((cyclesData) =>
          cyclesData.map((item) =>
            item.id === result.data.updateProcessCycle.id
              ? result.data.updateProcessCycle
              : item
          )
        );
        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }
    setModalState(ModalStates.OFF);
  };

  const handleMouseEnter = (record: any) => {
    setHoveredRow(record.key);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const updateCycle = async (record: any) => {
    try {
      const result = await updateProcessCycle({
        variables: {
          id: record.key, // Provide the processCycle ID to identify which processCycle to update
          title: record.cycle,
          status: record.status as WorkStatus,
          priority: record.priority as WorkPriority,
          deadliine: record.deadline,
        },
      });
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const setCycleStatus = (record: any, status: WorkStatus) => {
    record.status = status;
    updateCycle(record);
  };

  const onSelectProcessCycleStatus = async (ids: string[], status: string) => {
    try {
      console.log("onSelectStepStatus", status);
      await updateProcessCyclesStatus({
        variables: {
          ids: ids,
          status: status.replace(" ", "_"),
        },
      });
      refetch && refetch();
    } catch (error) {
      console.error(
        "Error updating step cycles" + ids + " status to " + status,
        error
      );
    }
  };

  // Filtering data
  let uniqueStatus: string[] = [];
  let uniqueProcess: String[] = [];

  cyclesData?.map((cycle: ProcessCycle) => {
    if (uniqueStatus.indexOf(cycle.status as string) === -1) {
      uniqueStatus.push(cycle.status as string);
    }
    if (uniqueProcess.indexOf(cycle.process?.title as string) === -1) {
      uniqueProcess.push(cycle.process?.title as string);
    }
  });

  function DeadLine(deadline: string) {
    if (!deadline) return "";
    const formattedDeadline = format(deadline, "yyyy-MM-dd HH:mm");
    const isDeadlinePast = isPast(new Date(deadline));
    const isDeadLineToday = isToday(new Date(deadline));
    const relativeTime = formatDistanceToNow(new Date(deadline));
    return isDeadlinePast || isDeadLineToday ? (
      <Alert
        type={isDeadlinePast ? "error" : "warning"}
        message={isDeadlinePast ? `${relativeTime} ago` : `in ${relativeTime}`}
      />
    ) : (
      formattedDeadline
    );
  }

  const columns: TableProps<ProcessInfo>["columns"] = [
    {
      title: "Space",
      dataIndex: "spaceTitle",
      key: "spaceTitle",
      hidden: cyclesBy !== "TENANT" || isMobile,
    },

    // only show the process if cyclesBy is not "PROCESS"

    {
      title: "Process",
      dataIndex: "process",
      key: "process",
      render: (text, record) => (
        <Link to={`/process/${record.processId}`}>{text}</Link>
      ),
      filterMode: "menu",
      filters: uniqueProcess.map((ptitle) => ({
        text: ptitle as string,
        value: ptitle as string,
      })),
      onFilter: (value, record: ProcessInfo) =>
        record.process.startsWith(value as string),
      hidden: cyclesBy === "PROCESS",
    },

    {
      title: "Cycle",
      dataIndex: "cycle",
      key: "cycle",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        text.replace(/_/g, " ") +
        ("In_Progress;To_Do".includes(text) && record.currentProcedure
          ? " (" + record.currentProcedure + ")"
          : ""),
      filterSearch: true,
      filterMode: "menu",
      defaultFilteredValue: [WorkStatus.ToDo, WorkStatus.InProgress],
      defaultSortOrder: "ascend",
      filters: uniqueStatus.map((status) => ({
        text: status.replace("_", " "),
        value: status,
      })),
      onFilter: (value, record: ProcessInfo) =>
        record.status.startsWith(value as string),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      render: (deadline) => DeadLine(deadline),
    },

    {
      title: "",
      key: "action",
      width: "100px",
      render: (record) => (
        <div
          style={{ height: "22px", display: "flex", alignItems: "center" }}
          onClick={(e) => e.stopPropagation()}
        >
          {(hoveredRow === record.key || isMobile) && (
            <div>
              <Button
                type="link"
                icon={<EditOutlined style={{ fontSize: "16px" }} />}
                onClick={() => handleEditCycle(record)}
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleAddCycle = () => {
    setModalState(ModalStates.ADD);
  };

  const TableHeader: React.FC<{ headerTitle: string }> = ({ headerTitle }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div className="title">
          {headerTitle}
          {isMobile && (
            <span>
              &nbsp; (
              {
                tableData.filter(
                  (record) => !"Done;Skipped;".includes(record.status)
                ).length
              }
              )
            </span>
          )}
        </div>
        {cyclesBy === "PROCESS" && (
          <Button onClick={handleAddCycle}>Add Cycle</Button>
        )}
      </div>
    );
  };

  function sortData(data: ProcessInfo[]) {
    return _.orderBy(
      data,
      [
        (item) => (item.priority === "Critical" ? 0 : 1),
        (item) => (item.deadline ? 0 : 1),
        "deadline",
        (item) =>
          item.priority === "Critical"
            ? 1
            : item.priority === "High"
            ? 2
            : item.priority === "Medium"
            ? 3
            : item.priority === "Low"
            ? 4
            : 5,
      ],
      ["asc", "asc", "asc"]
    );
  }

  function openDrawer(record: any) {
    ReactDOM.unstable_batchedUpdates(() => {
      const key = record.key;

      setCycleTreeData(key);

      console.log("data", data, "key", key);

      // create a flat array of checked keys, based on step cycle id's where status is "Done"
      const checked: string[] = cyclesData
        .filter((cycle: ProcessCycle) => cycle.id === key)
        .flatMap((cycle: ProcessCycle) =>
          (cycle.procedureCycles || []).flatMap(
            (procedureCycle: ProcedureCycle | null | undefined) => {
              if (!procedureCycle) return []; // Handle null and undefined values

              return (procedureCycle.stepCycles || []).map(
                (stepCycle: StepCycle | null | undefined) => {
                  if (!stepCycle) return null; // Handle null and undefined values

                  return stepCycle.status === WorkStatus.Done
                    ? stepCycle.id
                    : null;
                }
              );
            }
          )
        )
        .filter((id: string | null) => id !== null) as string[]; // Filter out null values and cast to string[]
      setCycleTreeChecked(checked);

      let firstAllToDo = true;

      const expanded: string[] = cyclesData
        .filter((cycle: ProcessCycle) => cycle.id === key)
        .flatMap((cycle: ProcessCycle) => {
          return (cycle.procedureCycles || []).flatMap(
            (
              procedureCycle: ProcedureCycle | null | undefined,
              index: number,
              array: (ProcedureCycle | null | undefined)[]
            ) => {
              if (!procedureCycle) return []; // Handle null and undefined values

              // Check if any step cycle is not in status Skip or Done
              const hasActiveStep = (procedureCycle.stepCycles || []).some(
                (stepCycle: StepCycle | null | undefined) => {
                  if (!stepCycle) return false; // Handle null and undefined values
                  return (
                    stepCycle.status !== WorkStatus.Skipped &&
                    stepCycle.status !== WorkStatus.Done
                  );
                }
              );

              // Check if all step cycles are in status ToDo
              const allToDo = (procedureCycle.stepCycles || []).every(
                (stepCycle: StepCycle | null | undefined) => {
                  if (!stepCycle) return false; // Handle null and undefined values
                  return stepCycle.status === WorkStatus.ToDo;
                }
              );

              // Include the first procedure cycle or if it has at least one active step cycle
              if ((allToDo && firstAllToDo) || (hasActiveStep && !allToDo)) {
                // Update firstAllToDo
                firstAllToDo = false;
                return procedureCycle.id || null;
              }

              return null;
            }
          );
        })
        .filter((id: string | null) => id !== null) as string[]; // Filter out null values and cast to string[]
      setCycleTreeExpanded(expanded);
      setDrawerTitle(record.cycle);
      setSelectedCycleId(record.key);
      setSelectedCycleStatus(record.status);
      setDrawerVisible(true);
    });
  }

  return (
    <div>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {drawerTitle}
            <SelectProcessCycleStatus
              processCycleId={selectedCycleId}
              defaultValue={selectedCycleStatus + ""}
              onSelectStatus={onSelectProcessCycleStatus}
              size="small"
            />
          </div>
        }
        placement="right"
        size="large"
        closable={true}
        onClose={() => {
          setDrawerVisible(false);
          setDrawerTitle("");
          refetch();
          console.log("refetching");
        }}
        open={drawerVisible}
      >
        {cycleTreeData && (
          <CycleTree
            processCycleId={cycleTreeData}
            checked={cycleTreeChecked}
            expanded={cycleTreeExpanded}
            refetch={refetch}
          />
        )}
      </Drawer>
      {isMobile && (
        <List itemLayout="vertical" dataSource={tableData}>
          <List.Item>
            {TableHeader({
              headerTitle:
                cyclesBy === "PROCESS"
                  ? "Process Cycles"
                  : cyclesBy === "TENANT"
                  ? "Account Cycles"
                  : "Space Cycles",
            })}
          </List.Item>
          {sortData(tableData)
            .filter((record) => !"Done;Skipped;".includes(record.status))
            .map((record: ProcessInfo) => {
              return (
                <List.Item onClick={() => openDrawer(record)}>
                  <Card>
                    <Descriptions
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{record.cycle}</span>
                          <Button
                            type="link"
                            icon={<EditOutlined style={{ fontSize: "16px" }} />}
                            onClick={(e) => {
                              handleEditCycle(record);
                              e.stopPropagation();
                            }}
                          />
                        </div>
                      }
                    >
                      <Item label="Status">
                        {record.status.replace("_", " ") +
                          ("In_Progress;To_Do".includes(record.status) &&
                          record.currentProcedure
                            ? " (" + record.currentProcedure + ")"
                            : "")}
                      </Item>
                      <Item label="Priority">{record.priority}</Item>
                      {cyclesBy !== "PROCESS" && (
                        <Item label="Process">
                          {
                            <Link to={`/process/${record.processId}`}>
                              {record.process}
                            </Link>
                          }
                        </Item>
                      )}
                      {record.deadline && (
                        <Item
                          labelStyle={{ alignItems: "center" }}
                          label="Deadline"
                        >
                          {DeadLine(record.deadline)}
                        </Item>
                      )}
                    </Descriptions>
                  </Card>
                </List.Item>
              );
            })}
        </List>
      )}
      {!isMobile && (
        <Table
          columns={columns}
          dataSource={sortData(tableData)}
          pagination={false}
          scroll={{ x: "max-content" }}
          title={() =>
            TableHeader({
              headerTitle:
                cyclesBy === "PROCESS"
                  ? "Process Cycles"
                  : cyclesBy === "TENANT"
                  ? "Account Cycles"
                  : "Space Cycles",
            })
          }
          rowKey="key"
          onRow={(record) => ({
            onMouseEnter: () => handleMouseEnter(record),
            onMouseLeave: handleMouseLeave,
            onClick: () => {
              openDrawer(record);
            },
          })}
        />
      )}
      <Modal
        zIndex={10000}
        title={modalState === ModalStates.ADD ? "Add Cycle" : "Edit Cycle"}
        open={modalState !== ModalStates.OFF}
        onCancel={handleCancel}
        footer={null}
      >
        <ProcessCycleForm
          onSave={handleSaveProcessCycle}
          onCancel={handleCancel}
          initialState={modalState === ModalStates.ADD ? "ADD" : "EDIT"}
          selectedRow={selectedRow}
        />
      </Modal>
    </div>
  );
};

export default CyclesTable;
